import { useState } from 'preact/hooks';

interface ApiDetailsRequestBodyProps {
  onRequestBodyChange: (requestBody: string) => void;
}

export default function ApiDetailsRequestBody({ onRequestBodyChange }: ApiDetailsRequestBodyProps) {
  const [requestBody, setRequestBody] = useState<string>("");

  const handleInputChange = (e: Event) => {
    const value = (e.target as HTMLTextAreaElement).value;
    setRequestBody(value);
    onRequestBodyChange(value);
  };

  return (
    <div class="mt-4 ">
      <h4 class="font-semibold text-lg md:text-xl text-slate-950 mb-2">Request Body</h4>
      <textarea
        class="w-full border rounded-xl p-4 bg-slate-200 shadow-md"
        placeholder="Enter request body in JSON format"
        value={requestBody}
        onInput={handleInputChange}
        rows={6}
      />
    </div>
  );
}
